import React, {useEffect, useState} from "react";
import {Box, Button, Flex, HStack, Text, Link} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/store.ts";
import {navigateBasedOnAuthState} from "../utils/navigationUtils.ts";
import {setAuthInfo} from "../store/slices/authSlice.ts";
import ProfilePicUploader from "./ProfilePicUploader.tsx";
import axios from "axios";

const ProfilePicUploadPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state: RootState) => state.auth);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    dispatch(setAuthInfo({ didSeeProfilePicPage: true }));
  }, []);

  const updateStatus = async () => {
    const response = await axios.put(`/api/user/updateOnboardingStatus`);
    if (response.status !== 200) {
      throw new Error("Failed to update onboarding status.");
    }
    dispatch(setAuthInfo({ isOnboardingComplete: true }));
  };

  const handleSkip = () => {
    updateStatus();
    navigateBasedOnAuthState(authState, navigate);
  };

  const handleContinue = () => {
    updateStatus();
    navigateBasedOnAuthState(authState, navigate);
  };

  const hasProfilePic = authState.profilePic;

  return (
    <Box bg="black" minH="100vh" px={4} position="relative">
      <Box position="absolute" top={4} right={4}>
        <Button
          variant="ghost"
          color="gray.300"
          _hover={{ color: "white" }}
          onClick={handleSkip}
        >
          Skip
        </Button>
      </Box>

      <Flex
        direction="column"
        maxW="container.sm"
        mx="auto"
        pt={12}
        align="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          color="white"
          mb={2}
          marginTop={20}
        >
          Add a profile photo
        </Text>

        <Text fontSize="lg" color="gray.300" mb={8}>
          Add a profile photo so your friends know it's you
        </Text>

        <ProfilePicUploader
          size="165px"
          onUploadStart={() => setIsUploading(true)}
          onUploadComplete={() => setIsUploading(false)}
        />

        <HStack spacing={4} width="100%" mt={6}>
          <Button
            onClick={() => document.querySelector('input[type="file"]').click()}
            isLoading={isUploading}
            bg="#FF0050"
            color="white"
            size="lg"
            width="100%"
            height="60px"
            borderRadius="full"
            _hover={{ bg: "#df0045" }}
            fontSize="md"
            fontWeight="semibold"
          >
            {hasProfilePic ? "Change Photo" : "Choose Photo"}
          </Button>

          {hasProfilePic && (
            <Button
              onClick={handleContinue}
              bg="white"
              color="black"
              size="lg"
              width="100%"
              height="60px"
              borderRadius="full"
              _hover={{ bg: "gray.100" }}
              fontSize="md"
              fontWeight="semibold"
            >
              Continue
            </Button>
          )}
        </HStack>
      
        <Flex
          position="absolute"
          bottom={4}
          left={0}
          right={0}
          justify="center"
          w="full"
          gap={2}
          color="#545454"
          fontSize="10px"
          fontWeight="bold"
        >
          <Link href="https://docs.google.com/document/d/1p3-fJ25CafAI13qyOYEZMu4rCH95JVaXRfsNejTyDD0/edit?usp=sharing" _hover={{ textDecoration: "underline" }}>
            Terms of Service
          </Link>
          <Text>•</Text>
          <Link href="https://docs.google.com/document/d/1zImTFVA7nfo3-ZcbCk6Hr1Y67W5P9GHEhi8PPaUAxdc/edit?usp=sharing" _hover={{ textDecoration: "underline" }}>
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProfilePicUploadPage;
