import React, { useRef, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { handleFileChange } from "../utils/uploadProfilePic.ts";
import { setAuthInfo } from "../store/slices/authSlice.ts";
import profilePicUpload from "../assets/profilePicUpload.png";

const ProfilePicUploader = ({
  size = "165px",
  onUploadComplete,
  onUploadStart,
}: {
  size: string;
  onUploadComplete: () => void;
  onUploadStart: () => void;
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  // Get current profile pic from Redux store
  const currentProfilePic = useSelector((state) => state.auth.profilePic);

  const handleUpload = async (event) => {
    if (!event.target.files || !event.target.files[0]) return;

    setIsLoading(true);
    onUploadStart?.();

    try {
      const url = await handleFileChange(event);
      if (url) {
        // Update profile pic in Redux store
        dispatch(setAuthInfo({ profilePic: url }));
        onUploadComplete?.(url);
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      // You might want to add error handling here
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box position="relative">
      <Box
        position="relative"
        cursor="pointer"
        onClick={handleClick}
        width={size}
        height={size}
      >
        <Image
          src={currentProfilePic || profilePicUpload}
          alt="Profile Picture"
          boxSize={size}
          borderRadius="full"
          objectFit="cover"
          opacity={isLoading ? 0.4 : 1}
          transition="opacity 0.2s"
        />

        {isLoading && (
          <Flex
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="blackAlpha.600"
            borderRadius="full"
            justify="center"
            align="center"
          >
            <Text color="white" fontSize="sm">
              Uploading...
            </Text>
          </Flex>
        )}
      </Box>

      <input
        type="file"
        accept="image/*"
        onChange={handleUpload}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
    </Box>
  );
};

export default ProfilePicUploader;
