import type { AuthState } from "../store/slices/authSlice";

export const determineNextRoute = (authState: AuthState): string => {
  if (!authState.displayName) {
    return "/completeDisplayName";
  }

  if (!authState.dateOfBirth) {
    return "/birthday";
  }

  if (
    !authState.profilePic &&
    !authState.isOnboardingComplete &&
    !authState.didSeeProfilePicPage
  ) {
    console.log("routing to /profilePicUpload");
    return "/profilePicUpload";
  }

  return "/";
};

export const navigateBasedOnAuthState = (
  authState: AuthState,
  navigate: (path: string) => void
): void => {
  const nextRoute = determineNextRoute(authState);
  console.log(`Navigating from ${window.location.pathname} to ${nextRoute}`);
  navigate(nextRoute);
};
