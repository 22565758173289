
const questions = [
    'What’s your go-to book, music, or movie recommendation?',
    'What 3 music artists do you like the most?',
    'What 3 foods or restaurants do you enjoy?',
    'If you could instantly become a master at any skill, what would it be?',
    'What is your ideal weekend routine?',
    'If you had unlimited money and time, what would you do?',
    'If you could have dinner with any three people, dead or alive, who would you pick?',
    'What emojis do you use the most?',
    'What’s a simple thing you love that instantly makes any day better?',
    'How would your best friend describe you?',
    'What was your biggest (or funniest) childhood misconception?',
    'What’s the strangest date you’ve ever been on?',
    'You have only one meal left to eat, what does it consist of?',
    'What is your most treasured possession?',
    'What was your very first job?',
    'Which living person do you most admire?',
    'What’s the last book that you couldn’t put down?',
    'If you could sit down with your 15-year old self, what would you tell yourself?',
    'Which historical figure do you most identify with?',
    'What’s one piece of advice you received that has stuck with you? Who gave it to you?',
    'Do you seek or avoid routines in your life? How come?',
    'What is a dream that has come true for you?',
    'How did your parents meet?',
    'What is a favorite game of yours? Do you have any specific memories from playing it?',
    'What is something you’re afraid to do but know you will do someday? What would make you do it?',
    'If you could be invisible for a day, what would you do?',
    'What is an early memory that always makes you smile?',
    'What is an invention or piece of technology you hope will be built in your lifetime?',
    'What is one of the most important or influential books you’ve read?',
    'If you could get one law passed, what would it be? What would be the consequences of violating the law?',
    'What is the most unusual place you’ve visited?',
    'What is one habit you could never give up?',
    'If you could live during any period in history, which would you choose? If you could go back in time for a specific',
    'If you could go back to school, what would it be for? What draws you to that subject?',
    'Who is the smartest person you know? What is something they have taught you?',
    'What song has a special significance in your life and why?',
    'What is something many people own that you will never buy?',
    'If you could teach everyone in the world one concept, what would it be?',
    'What is your favorite moment from a book or movie? Why?',
    'You have an hour. What situation can you put someone in to learn the most about them?',
    'If you could be a fly on the wall and witness any event in the past, what would it be?',
    'What makes a good friend, and how do you become one? Do you have a role model?',
    'What were the influences and reasons you pursued your career?',
    'If you were by yourself and could do only one thing for a whole day, what would it be?',
    'What is a simple pleasure of yours?',
    'What is mankind’s greatest invention?',
    'What activity makes you lose track of time?',
    'What immediately makes you think more highly of someone?',
    'What is your greatest wish? Where does it come from?',
    'What is your favorite smell or scent?',
    'What are your favorite restaurants?',
    'What are your favorite cities?',
    'When was your last relationship?',
    'What do you look for in a partner?',
    'Do you believe in love?',
    'What is your favorite sport?',
    'What is your favorite place to go on vacation?',
    'Do you prefer the sea or the mountain?',   
    'Do you enjoy your work?',
    'What are your goals for your career?',
    'What makes you happy?',
    'What are your favorite foods?',
    'What is the silliest thing you’ve ever done for someone you liked?',
    'What did you study and why did you choose to study this?',
    'What do you do for work?',
    'What do you like to do in your free time?',
    'Where do you live?',
    'What are your favorite places in the city you live in?',
    'Where is your dream place to live?',
    'Do you like to travel or do you prefer to have a routine?',
    'Do you like tattoos? Do you have any?',
    'Where were you born?',
    'What is your favorite movie?',
    'Do you have a partner? If so - how did you meet?',
    'Do you have any pets? If not, do you want one?',
    'If you could have any superpower, which one would you have any why?',
    'Tell me something which is true that almost nobody agrees with you on?',
    'How successful are you going to be?',
    'What is something you believe is true and false at the same time?',
    'What is the most important thing in your life?',
    'What is your horoscope?',
    'What is your personality type?',
    'What is your Enneagram type?',
    'What is your MBTI type?',
    'Tell me a joke?',
    'What is your favorite color?',
    'What is your favorite animal?',
    'What is your favorite food?',
    'What is your favorite movie?',
    'What is your favorite book?',
    'What is your favorite song?',
    'What is your favorite TV show?',
    'What is your favorite game?',
    'What is your favorite vacation spot?',
    'What is your favorite type of music?',
    'Roast yourself',
    'What is your favorite season?',
    'What is your favorite holiday?',
];

export const pickRandomQuestion = () => {
    const randomIndex = Math.floor(Math.random() * questions.length);
     return questions[randomIndex];
}