import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import type {RootState} from "../store/store.ts";

const PrivateRoute = () => {
  const authState = useSelector((state: RootState) => state.auth);

  console.log("authState", authState);

  if (
    authState.isLoading ||
    authState.showEnterEmailModal ||
    authState.showLinkExpiredModal
  ) {
    // don't render anything
    return null;
  }

  if (!authState.isAuthenticated) {
    console.log("redirecting to signup from private route");
    return <Navigate to="/signup" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
