// AutoResizeTextarea.js
import React from "react";
import { Textarea, TextareaProps } from "@chakra-ui/react";
import ResizeTextarea, { TextareaAutosizeProps } from "react-textarea-autosize";

interface AutoResizeTextareaProps
  extends TextareaProps,
    Omit<TextareaAutosizeProps, keyof TextareaProps> {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const AutoResizeTextarea = React.forwardRef<
  HTMLTextAreaElement,
  AutoResizeTextareaProps
>((props, ref) => (
  <Textarea
    minH="unset"
    overflow="hidden"
    w="100%"
    resize="none"
    ref={ref}
    minRows={1}
    as={ResizeTextarea}
    {...props}
  />
));

AutoResizeTextarea.displayName = "AutoResizeTextarea";
