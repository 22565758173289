// firebaseConfig.js

import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBhhB0DlXsG2IDmYlVJqFZzI5UNYMdzWCU",
  authDomain: "dots-755b1.firebaseapp.com",
  projectId: "dots-755b1",
  storageBucket: "dots-755b1.appspot.com",
  messagingSenderId: "793752813535",
  appId: "1:793752813535:web:5fde39ad8f5c906c840e6a",
  measurementId: "G-SGE0GPJWSF",
};

const app = initializeApp(firebaseConfig);

// Get Firebase Auth instance
const auth = getAuth(app);

export { app, auth };
