import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  Box,
  Button,
  Flex,
  Image,
  InputGroup,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { AutoResizeTextarea } from "./AutoResizeTextarea";
import { useStatsigClient } from "@statsig/react-bindings";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import ProfilePicUploader from "./ProfilePicUploader";
import OnboardingProgressIndicator from "./OnboardingProgressIndicator";
import {
  hasEnoughNotesToAskQuestions,
  MIN_NOTES_FOR_ONBOARDING_COMPLETE,
  setAuthInfo,
} from "../store/slices/authSlice";
import { useSelector } from "react-redux";
import defaultProfilePic from "../assets/profile_pic_anon.png";
import { colors } from "../styles/colors";
import { sizing } from "../styles/sizing";
import lightbulb from "../assets/lightbulb.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { addAskedQuestion } from "../store/slices/anonymousQuestionsSlice";
import { shareProfile } from "../utils/shareUtils";
import diceIcon from "../assets/dice.svg";
import { getStatsigClient } from "../utils/statsig";
import { pickRandomQuestion } from "../utils/questions";

const ANONYMOUS_USER_UUID = "db8b91c5-f6ca-45ba-9328-5c793e536ccd";

const AskComponent = ({
  prefillQuestion = "",
  isInternal,
  onQuestion = () => {},
}) => {
  const authState = useAppSelector((state) => state.auth);
  const askerUserId = authState.userId ?? ANONYMOUS_USER_UUID;
  const [displayName, setDisplayName] = useState(authState.displayName);
  const [receiverUserId, setReceiverUserId] = useState(authState.userId);
  const [externalProfilePicture, setExternalProfilePicture] =
    useState(defaultProfilePic);
  const { uniqId } = useParams(); // Only applies to external ask
  console.log("Received userId in AskComponent:", askerUserId);
  const [question, setQuestion] = useState("");
  const [askedQuestion, setAskedQuestion] = useState(
    isInternal ? "Ask yourself anything or get recs" : "Ask me a question!"
  );
  const [answer, setAnswer] = useState("");
  const [isStreamingAnswer, setIsStreamingAnswer] = useState(false);
  const fetchController = useRef(new AbortController());
  const hasEnoughNotes = useSelector(hasEnoughNotesToAskQuestions);
  const toast = useToast();
  const { client: statsigClient } = useStatsigClient();
  const dispatch = useAppDispatch();
  const textareaRef = useRef(null);
  const navigate = useNavigate();
  const anonymousQuestions = useAppSelector(
    (state) => state.anonymousQuestions
  );

  useEffect(() => {
    if (!uniqId) {
      return;
    }
    statsigClient.logEvent("start_fetching_asklink_data");
    const fetchAskeeData = async () => {
      try {
        const userRes = await axios.post(`/api/getAskeeUser`, { uniqId });
        setReceiverUserId(userRes.data.user.userId);
        const user = userRes.data.user;
        setDisplayName(user.displayName);
        if (user.profilePic) {
          setExternalProfilePicture(user.profilePic);
        }
      } catch (error) {
        console.error("Error:", error);
        if (error.response?.status === 404) {
          if (authState.isAuthenticated) {
            navigate("/");
          } else {
            navigate("/signup");
          }
          return;
        }
        navigate("/");
      }
    };

    if (uniqId) {
      fetchAskeeData();
    }
    statsigClient.logEvent("end_fetching_asklink_data");
  }, [uniqId, authState.isAuthenticated]);

  useEffect(() => {
    if (prefillQuestion) {
      setQuestion(prefillQuestion); // Set the query state
      handleAskClick(prefillQuestion); // Pass the prefillQuestion to the function
    }
  }, [prefillQuestion]); // Depend on prefillQuestion

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleQuestionBoxFocus = (e) => {
    getStatsigClient()?.logEvent("tap_question_box", e.target.value);
  };

  const handleAskClick = async (arg) => {
    // Prevent default behavior if arg is an event
    if (arg && arg.preventDefault) {
      arg.preventDefault();
    }
    onQuestion();

    if (
      authState.numNotesOrQuestionsAnswered <
        MIN_NOTES_FOR_ONBOARDING_COMPLETE &&
      isInternal
    ) {
      toast({
        title: "Incomplete Profile",
        description: `Please complete your profile by adding more notes to ask a question`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (!authState.userId && anonymousQuestions.count >= 3) {
      return;
    }

    // Determine whether arg is a string (question) or an event
    const query = typeof arg === "string" ? arg : question;

    setIsStreamingAnswer(true);
    setAnswer("");

    // Abort the previous request if it exists
    if (fetchController.current) {
      fetchController.current.abort();
    }
    fetchController.current = new AbortController();
    const { signal } = fetchController.current;

    try {
      console.log("Question:", query);

      const sessionToken = localStorage.getItem("sessionToken");
      const API_BASE_URL =
        process.env.NODE_ENV === "production"
          ? "https://dots.link"
          : "http://localhost:3002";

      const res = await fetch(`${API_BASE_URL}/api/askBio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify({
          query,
          receiverUserId,
          askerUserId,
        }),
        signal,
      });
      if (!res.body) {
        throw new Error("Failed to get response body");
      }
      if (receiverUserId === askerUserId) {
        statsigClient.logEvent("submit_question_to_own_profile", query);
      } else {
        statsigClient.logEvent("submit_question_to_external_profile", query);
      }

      const reader = res.body.getReader();
      const decoder = new TextDecoder();
      setAskedQuestion(question);

      const askId = res.headers.get("X-Ask-ID");

      // Track the question for anonymous users
      if (!authState.userId && askId) {
        // We want to keep track of the question asked for anonymous users,
        // for limiting the number of questions they can ask, across tabs and reloads
        dispatch(addAskedQuestion({ askId }));
      }

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        const newChunk = decoder.decode(value, { stream: true });
        console.log("New Chunk Content:", newChunk);
        setAnswer((prevResponse) => prevResponse + newChunk);
      }

      // After finishing streaming
      setIsStreamingAnswer(false);
      console.log("Final Response:", answer);

      // clear the question
      setQuestion("");
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
      }
      setIsStreamingAnswer(false);
    }
  };

  const handleStopClick = () => {
    fetchController.current.abort(); // Abort the fetch operation
    setIsStreamingAnswer(false); // Ensure streaming is stopped
    statsigClient.logEvent("stop_question_generation");
  };

  const handleGetStartedClick = async () => {
    dispatch(setAuthInfo({ currentTabIndex: 0 }));
  };

  const handleClickAnswer = () => {
    setAnswer("");
    setQuestion("");
    setAskedQuestion(
      isInternal ? "Ask yourself anything or get recs" : "Ask me a question!"
    );
    statsigClient.logEvent("click_clear_question_answer");
  };

  // Focus the text area when we clear the answer
  useEffect(() => {
    if (answer === "" && question === "") {
      textareaRef.current?.focus();
    }
  }, [answer, question]);

  const mainButtonContent = useMemo(() => {
    if (!hasEnoughNotes && isInternal) {
      return "Get Started";
    } else if (isStreamingAnswer) {
      return (
        <Image src="/assets/ask_stop_icon.svg" alt="Stop" boxSize="16px" />
      );
    } else if (answer && isInternal && authState.isAuthenticated) {
      return "Share your Dot profile!";
    } else if (answer && !isInternal && !authState.isAuthenticated) {
      return "Get your own profile!";
    } else {
      return "Submit";
    }
  }, [isStreamingAnswer, hasEnoughNotes, answer]);

  const shouldDisableMainButton = () => {
    let disabled = false;
    if (!hasEnoughNotes && isInternal) {
      disabled = true;
    } else if (!question.trim() && !answer) {
      disabled = true;
    }
    return disabled;
  };

  const handleMainButtonClick = async (event) => {
    if (!hasEnoughNotes && isInternal) {
      console.log(`Handling get started click`);
      handleGetStartedClick();
    } else if (isStreamingAnswer) {
      console.log(`Handling Stop click`);
      handleStopClick();
    } else if (answer) {
      console.log(`Handling Get your own profile! click`);
      if (isInternal && authState.uniqId) {
        await shareProfile(authState.uniqId, toast);
      } else {
        navigate("/signup");
      }
      statsigClient.logEvent("click_get_your_own_profile");
    } else {
      console.log(`Handling Ask click`);
      await handleAskClick(event);
    }
  };

  const onRandomDiceClick = async () => {
    handleClickAnswer();

    console.log(
      `Generating interesting question for receiverUserId: ${receiverUserId} and askerUserId: ${askerUserId}`
    );
    if (authState.isAuthenticated) {
      const question = await axios.post(
        "/api/ask/generateInterestingQuestion",
        {
          askerUserId:
            askerUserId !== ANONYMOUS_USER_UUID ? askerUserId : undefined,
          receiverUserId,
        }
      );

      setQuestion(question.data.question);
    } else {
      const question = pickRandomQuestion();
      setQuestion(question);
    }
  };

  return (
    <Flex direction="column" align="center" width="100%">
      {isInternal && !authState.isOnBoardingTutorialComplete && (
        <OnboardingProgressIndicator
          currentStep={authState.numNotesOrQuestionsAnswered}
        />
      )}

      {isInternal ? (
        <ProfilePicUploader
          size="100px"
          onUploadStart={() => {}}
          onUploadComplete={() => {}}
        />
      ) : (
        <Image
          src={externalProfilePicture}
          alt="Profile Picture"
          boxSize="100px"
          borderRadius="full"
          objectFit="cover"
        />
      )}
      {/* Text Description */}
      <Text
        marginTop={4}
        color="white"
        textAlign="center"
        mb={4}
        width="100%"
        fontSize={{ base: "16px", md: "md", lg: "lg" }}
        lineHeight="1.4"
      >
        {displayName}
      </Text>

      <Box
        width="100%"
        bg={colors.gray100}
        py="24px"
        px="16px"
        borderTopRadius="30px"
      >
        <Text
          width="100%"
          textAlign="left"
          fontWeight="bold"
          textColor="black"
          fontSize={{ base: "md", md: "md", lg: "lg" }}
        >
          {askedQuestion}
        </Text>
      </Box>

      <Box
        bg="white"
        w="100%"
        p="16px"
        borderBottomRadius="30px"
        _hover={{
          boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
          transform: "translateY(-1px)",
        }}
        fontSize={{ base: "md", md: "md", lg: "lg" }}
      >
        <Flex
          onClick={
            answer
              ? handleClickAnswer
              : () => {
                  textareaRef.current?.focus();
                }
          }
          mb={4}
          align="start"
          justify="start"
          width="100%"
          minH="150px"
        >
          {answer ? (
            <Box width="100%">
              <ReactMarkdown
                components={{
                  p: ({ children }) => (
                    <Text
                      textColor="black"
                      fontWeight="bold"
                      textAlign="left"
                      width="100%"
                      whiteSpace="pre-wrap"
                      fontSize={{ base: "md", md: "md", lg: "lg" }}
                    >
                      {children}
                    </Text>
                  ),
                }}
              >
                {answer + (isStreamingAnswer ? " ..." : "")}
              </ReactMarkdown>
            </Box>
          ) : (
            <AutoResizeTextarea
              ref={textareaRef}
              fontWeight="bold"
              placeholder={
                isInternal ? "Type a question..." : "Send me a question..."
              }
              fontSize={{ base: "md", md: "md", lg: "lg" }}
              value={question}
              onChange={handleInputChange}
              onFocus={handleQuestionBoxFocus}
              color="black"
              border="none"
              _placeholder={{ color: colors.gray300 }}
              lineHeight="1.4" // Adjust line height for vertical centering
              p={0}
              height="100%"
              _focus={{
                outline: "none",
                boxShadow: "none",
              }}
              onKeyDown={(e) => {
                if (
                  e.key === "Enter" &&
                  (e.metaKey || e.ctrlKey) &&
                  !shouldDisableMainButton()
                ) {
                  // Allow regular Enter for new lines
                  e.preventDefault();
                  handleAskClick(question);
                }
              }}
            />
          )}
        </Flex>
        <Image
          onClick={onRandomDiceClick}
          src={diceIcon}
          alt="Dice Icon"
          rounded="full"
          justifySelf="end"
          shadow="dark-lg"
        />
      </Box>

      <Flex align="center" justify="center" gap={2} marginTop={3} mb="110px">
        <Image src={lightbulb} />
        <Text fontSize="12px" textColor="white">
          Tap the response to ask a new question
        </Text>
      </Flex>

      <Box
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        bg="#1c1c1c"
        p={4}
        textAlign="center"
      >
        {answer &&
          !isStreamingAnswer &&
          !isInternal &&
          !authState.isAuthenticated && (
            <Text color="white" fontSize="14px" mb={2}>
              Having fun? Get your own profile 👇
            </Text>
          )}
        <Button
          width="100%"
          maxW={sizing.maxAppContentWidthPx}
          isDisabled={shouldDisableMainButton()}
          mx="auto"
          onClick={handleMainButtonClick}
          bg={colors.dotsRed}
          color="white"
          height="60px"
          borderRadius="full"
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
          _disabled={{
            bg: colors.dotsRed,
            opacity: 0.6,
            cursor: "not-allowed",
            _hover: {
              bg: colors.dotsRed,
              opacity: 0.6,
            },
            _active: {
              bg: colors.dotsRed,
              opacity: 0.6,
            },
            _focus: {
              bg: colors.dotsRed,
              opacity: 0.6,
            },
          }}
        >
          {mainButtonContent}
        </Button>
      </Box>
    </Flex>
  );
};

export default AskComponent;
