import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  TabIndicator,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import HomeTab from "./HomeTab";
import Ask from "./Ask";
import { useStatsigClient } from "@statsig/react-bindings";
import { useAppDispatch, useAppSelector } from "../store/hooks.ts";
import { setAuthInfo } from "../store/slices/authSlice.ts";
import { navigateBasedOnAuthState } from "../utils/navigationUtils.ts";
import homeTabIconSelected from "../assets/homeTabIconSelected.png";
import homeTabIconUnselected from "../assets/homeTabIconUnselected.png";
import shareTabIconSelected from "../assets/shareTabIconSelected.png";
import shareTabIconUnselected from "../assets/shareTabIconUnselected.png";
import profileTabIconSelected from "../assets/profileTabIconSelected.png";
import profileTabIconUnselected from "../assets/profileTabIconUnselected.png";
import lockIcon from "../assets/lockIcon.png";
import ShareTab from "./ShareTab.tsx";
import LogoDropdown from "./LogoDropdown.tsx";
import { sizing } from "../styles/sizing";
import {getStatsigClient} from "../utils/statsig";

function MainPage() {
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTab = location.state?.selectedTab;
  const [prefilledQuestion, setPrefilledQuestion] = useState("");
  const { client: statsigClient } = useStatsigClient();

  // Define tab icons configuration
  const tabIcons = [
    {
      selected: homeTabIconSelected,
      unselected: homeTabIconUnselected,
      disabled: false,
    },
    {
      selected: shareTabIconSelected,
      unselected: shareTabIconUnselected,
      disabled: !authState.isOnBoardingTutorialComplete,
    },
    {
      selected: authState.profilePic || profileTabIconSelected,
      unselected: authState.profilePic || profileTabIconUnselected,
      disabled: !authState.isOnBoardingTutorialComplete,
      isProfilePic: !!authState.profilePic,
    },
  ];

  // Helper function to get the appropriate icon based on index
  const getTabIcon = (index: number) => {
    const icon =
      authState.currentTabIndex === index
        ? tabIcons[index].selected
        : tabIcons[index].unselected;
    return icon;
  };

  useEffect(() => {
    const completedOnboarding = sessionStorage.getItem("completedOnboarding");
    if (completedOnboarding) {
      setPrefilledQuestion(
        "i want you to give me: 1) Emoji: with my emoji 2) MBTI: my MBIT 3) Enneagram: my enneagram 4) Spirit Animal: my spirit animal and 5) Recommendation: An obscure recommendation (movie, song, book, TV) that i may enjoy with the year and content type. only provide me the concise answer with google link and a quick reason why in 10 words or less in one column"
      );
      sessionStorage.removeItem("completedOnboarding");
    }
  }, []);

  useEffect(() => {
    navigateBasedOnAuthState(authState, navigate);
  }, [authState, navigate]);

  useEffect(() => {
    // Set the initial tab if selectedTab is provided in navigation state
    if (
      selectedTab !== undefined &&
      selectedTab !== authState.currentTabIndex
    ) {
      dispatch(setAuthInfo({ currentTabIndex: selectedTab }));
    }
  }, [selectedTab]);

  const handleTabsChange = (index: number) => {
    if (index === authState.currentTabIndex || tabIcons[index].disabled) {
      return;
    }
    statsigClient.logEvent("click_tab_change", index);
    if(index === 2) {
      getStatsigClient().logEvent('click_to_profile_page')
    }
    dispatch(setAuthInfo({ currentTabIndex: index }));
  };

  if (!authState.isAuthenticated) {
    return null;
  }

  return (
    <Flex
      style={{
        backgroundColor: "#1C1C1C",
        overflowX: "hidden",
      }}
      justifyContent="center"
      width="100%"
    >
      <Box maxWidth={sizing.maxAppWidthPx} width="full">
        {/* Fixed header section */}
        <Box
          position="fixed"
          top={0}
          zIndex={1000}
          width="inherit"
          maxWidth="inherit"
          bg="#1C1C1C"
          px={4}
          pt={4}
        >

          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom={4}
            width="full"
          >
            <LogoDropdown />
            <Flex alignItems="center" gap={4}>
              {[0, 1, 2].map((index) => (
                <Box key={index} position="relative">
                  <Image
                    src={getTabIcon(index)}
                    width="auto"
                    height={tabIcons[index].isProfilePic ? "35px" : "32px"}
                    onClick={() => handleTabsChange(index)}
                    cursor={
                      tabIcons[index].disabled ? "not-allowed" : "pointer"
                    }
                    transition="opacity 0.2s"
                    _hover={{ opacity: tabIcons[index].disabled ? 0.2 : 0.8 }}
                    opacity={tabIcons[index].disabled ? 0.2 : 1}
                    filter={
                      tabIcons[index].disabled ? "grayscale(100%)" : "none"
                    }
                    borderRadius={tabIcons[index].isProfilePic ? "full" : "none"}
                    border={
                      tabIcons[index].isProfilePic && authState.currentTabIndex === index
                        ? "2px solid #ff0050"
                        : "none"
                    }
                    objectFit={tabIcons[index].isProfilePic ? "cover" : "cover"}
                  />
                  {tabIcons[index].disabled && (
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      width="100%"
                      height="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image
                        src={lockIcon}
                        width="12px"
                        height="15px"
                        position="absolute"
                        aria-label="Locked feature"
                      />
                    </Box>
                  )}
                </Box>
              ))}
            </Flex>
          </Flex>
        </Box>

        {/* Add padding to account for fixed header */}
        <Box pt="80px" px={4}>
          <Tabs
            variant="unstyled"
            color="white"
            index={authState.currentTabIndex}
            onChange={handleTabsChange}
          >
            <TabIndicator
              mt="-15px"
              height="4px"
              bg="#FF0050"
              sx={{ width: "10px" }}
            />

            <TabPanels>
              <TabPanel p={0}>
                <HomeTab />
              </TabPanel>
              <TabPanel p={0}>
                <ShareTab />
              </TabPanel>
              <TabPanel p={0}>
                <Ask prefillQuestion={prefilledQuestion} isInternal={true} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Flex>
  );
}

export default MainPage;
