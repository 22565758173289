export const shareProfile = async (uniqId: string, toast: any) => {
  const userDotLink =
    process.env.NODE_ENV === "production"
      ? `dots.link/profile/${uniqId}`
      : `localhost:5173/profile/${uniqId}`;

  try {
    // For medium or larger screens, copy to clipboard
    await navigator.clipboard.writeText(userDotLink);
    toast({
      title: "Link Copied",
      description: "The link has been copied to your clipboard.",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });

    // For smaller screens, use the native share dialog
    if (navigator.share) {
      await navigator.share({
        title: "Check out my Dot",
        url: `profile/${uniqId}`,
      });
      console.log("Content shared successfully");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
