import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface AnonymousQuestionsState {
  count: number;
  askedQuestions: Array<{
    askId: string;
    timestamp: string;
  }>;
}

const initialState: AnonymousQuestionsState = {
  count: 0,
  askedQuestions: [],
};

// Helper function to keep only the last 10 questions
const limitQuestions = (
  questions: Array<{ askId: string; timestamp: string }>
) => {
  return questions.slice(-10); // Keep only the last 10 questions
};

const anonymousQuestionsSlice = createSlice({
  name: "anonymousQuestions",
  initialState,
  reducers: {
    incrementQuestionCount: (state) => {
      state.count += 1;
    },
    addAskedQuestion: (state, action: PayloadAction<{ askId: string }>) => {
      // Add new question and limit the array size
      const newQuestions = limitQuestions([
        ...state.askedQuestions,
        {
          askId: action.payload.askId,
          timestamp: new Date().toISOString(),
        },
      ]);

      state.askedQuestions = newQuestions;
      state.count = Math.min(state.count + 1, 3); // Cap at 3 questions
    },
    clearAnonymousQuestions: () => initialState,
    // Add a cleanup reducer
    cleanupStorage: (state) => {
      state.askedQuestions = limitQuestions(state.askedQuestions);
      state.count = Math.min(state.askedQuestions.length, 3);
    },
  },
});

export const {
  incrementQuestionCount,
  addAskedQuestion,
  clearAnonymousQuestions,
  cleanupStorage,
} = anonymousQuestionsSlice.actions;
export default anonymousQuestionsSlice.reducer;
